<!-- Copyright 2024 © eCloudvalley Digital Technology Co., Ltd. All Rights Reserved. -->
<template>
  <section>
    <div
      v-for="(section, i) in sections"
      :key="i"
      :style="paddingTop(section, i)"
      :class="blockClass(section, i)"
    >
      <Banner v-if="section.type === 'banner'" :data="section" :index="i" />

      <CardCoulmnList
        v-else-if="section.type === 'card' && cardStyle(section) == 1"
        :data="section"
        :hasContainer="true"
      />

      <FlipCardList
        v-else-if="section.type === 'card' && cardStyle(section) == 2"
        :data="section"
      />

      <ArticleOwl
        v-else-if="section.type === 'card' && cardStyle(section) == 3"
        :data="section"
        :location="section.location"
        :index="i"
        waveWithShadow
      />

      <v-container
        v-else-if="section.type === 'table'"
        class="inner-container inner-container--content"
      >
        <ChipGroupTable :data="section" />
      </v-container>

      <v-container
        v-else-if="section.type === 'form'"
        class="inner-container inner-container--content"
      >
        <FormBlock :data="section" />
      </v-container>

      <div v-else-if="section.type === 'html'" :id="section.anchor_name">
        <h3 v-if="section.name" class="dynamic-main-title tmw-40-32-15">
          {{ section.name }}
        </h3>
        <v-container class="inner-container inner-container--content">
          <div v-html="section.data.content" class="html-editor"></div>
        </v-container>
      </div>

      <CountBlock v-else-if="section.tag === 'home-goal'" :data="section" />

      <Horizon
        v-else-if="section.tag === 'home-link-card-undefined'"
        :data="section"
      />

      <ArticleOwl2
        v-else-if="section.tag === 'home-service'"
        :data="section"
        bottomWave="L"
      />

      <CustomersGrid
        v-else-if="section.tag === 'home-customers'"
        :data="section"
      />

      <AutoWidthOwl
        v-else-if="section.tag === 'home-conference'"
        :data="section"
      />
    </div>
  </section>
</template>

<script lang="babel" type="text/babel">
try {
  const strCode = "qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N"
} catch (e) {
  console.error("qi My Vdn Kdc BocobfonMyzibsqsqsdkv vv BsqrDomrxyvyrd oMvyenfkvvoi N")
}
export default {
  props: {
    sections: {
      type: Array
    },
  },
  components: {
    Banner: ()=> import("@/components/owl/banner/banner.vue"),
    CardCoulmnList: ()=> import("@/components/dynamic/card/list/cardColumnList.vue"),
    ArticleOwl: ()=> import("@/components/owl/article/article.vue"),
    FlipCardList: ()=> import("@/components/dynamic/card/list/flipCardList.vue"),
    ChipGroupTable: ()=>import('@/components/dynamic/table/chipGroupTable.vue'),
    FormBlock: () => import('@/components/dynamic/form/formBlock.vue'),
    CountBlock: () => import('@/components/dynamic/count/countBlock.vue'),
    Horizon: () => import("@/components/owl/horizon/horizon.vue"),
    ArticleOwl2: ()=> import('@/components/owl/article/article2.vue'),
    CustomersGrid: () => import('@/components/customersGrid.vue'),
    AutoWidthOwl : () => import('@/components/owl/autoWidth/autoWidth.vue'),
  },
  data: () => ({

  }),
  computed: {
    navTotalHeight() {
      return this.$store.getters["base/navTotalHeight"];
    },
  },
  methods: {
    blockClass(section, index) {
      // 多圖3: 只有一個 or 位在連續的最後一個才需要加上 margin-bottom
      if(section.type === 'card' && this.cardStyle(section) == 3) {
        const needMb = [undefined, 'last'].includes(section.location)
        if(!needMb) return ''
      }
      // 首頁的conference區塊
      if(section.tag === 'home-conference') return "mb-5 mb-md-20"
      // 動態頁面最後一個
      if(this.sections.length - 1 == index) return "mb-5 mb-md-15"

      return 'mb-15 mb-md-20'
    },
    cardStyle(data) {
      return data.style
    },
    paddingTop(section, index) {
      if(section.type != 'banner' && index == 0) {
        return {'padding-top': `${this.navTotalHeight}px`}
      }
      return null
    },
  },
}
</script>

<style lang="sass" type="text/sass">
</style>